// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'bootstrap'
import "@fortawesome/fontawesome-free/js/all";
import 'select2';
import 'select2/dist/css/select2.css';
import 'plugins/flatpickr'

require("@rails/ujs").start()
require("@rails/activestorage")
require("channels")
require("jquery")
require("jquery-ui")
require("parsleyjs")
require("packs/adminlte")
require('select2')
require("packs/nested_form")
require("packs/datatables")
require("packs/fullcalendar")
require("packs/patient")
require("chartkick")
require("chart.js")
require("turbolinks")

require("stylesheets/application.scss")
const images = require.context('../images', true)

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

var parseRequirement = function (requirement) {
  if (isNaN(+requirement))
    return parseFloat(jQuery(requirement).val());
  else
    return +requirement;
};

window.Parsley.addValidator('lte', {
  validateString: function (value, requirement) {
    return parseFloat(value) <= parseRequirement(requirement);
  },
  priority: 32
});

$(document).ready(function() {
  // function disableBack() { window.history.forward() }

  // window.onload = disableBack();
  // window.onpageshow = function(evt) { if (evt.persisted) disableBack() }

  window.addEventListener('DOMContentLoaded', () => {
    $('.custom-select2').select2()
  })
  setTimeout(function() {
    $('#flash-messages-section').slideUp('1000');
  }, 5000);

  $('.remove-nested').on('click', function (e) {
    $(this).closest('.fields').find('.form-control').removeAttr('required');
  })
})
