$(function() {
  $('.custom-select2').select2()

  $(document).on('nested:fieldAdded', function(event) {
    $('.custom-select2').select2()
  })

  $(document).ready(function() {
    $('#all_notes_datatable').dataTable({
      "order": [[ 0, "desc" ]],
      'pageLength': 5,
      'searching': false,
      'bInfo': false,
      'bLengthChange': false,
      language : {
        sLengthMenu: "_MENU_"
      }
    });
  })
})
